@charset "utf-8";

$container: 960px;
$container-sp: 375px;

@mixin clearfix {
  &:after{
    content:"";
    display: block;
    clear: both;
  }
}


// 両端揃え
@mixin flexbox-01{
  display: flex;
  justify-content: space-between;
  //margin:0 -10px;
  //padding: 0 10px;
  > div{
    //flex:1;
  }
  > span{
    display: block;
  }
  @media only screen and (max-width: 979px) {
    @content;
    flex-direction: column;
    margin:0;
    width: auto !important;
    > div{
      width: auto !important;
    }
    &.no-column{
      flex-direction: row;
    }
  }
}

// 等間隔
@mixin flexbox-02{
  display: flex;
  justify-content: space-around;
  //margin:0 -10px;
  //padding: 0 10px;
  > div{
    //flex:1;
  }
  > span{
    display: block;
  }
  @media only screen and (max-width: 979px) {
    @content;
    flex-direction: column;
    margin:0;
    width: auto !important;
    > div{
      width: auto !important;
    }
  }
}

// 左揃え
@mixin flexbox-03{
  display: flex;
  justify-content: flex-start;
  /* margin:0 -10px; */
  > span{
    display: block;
  }
  @media only screen and (max-width: 979px) {
    @content;
    flex-direction: column;
    &.no-column{
      flex-direction: row;
    }
  }
}


// 背景100％
@mixin bg100($height:100%,$bg-image:null,$bg-repeat:repeat-x,$bg-color:#ebebeb){
  position: relative;
  &:before{
    content:"";
    width: 1000%;
    height: $height;
    display: block;
    position: absolute;
    top: 0;
    left: -500%;
    background-image: $bg-image;
    background-position: left top;
    background-repeat: $bg-repeat;
    background-color: $bg-color;
    z-index: -1;
  }
}

@mixin img-icon($bg-image:null,$color:null,$padding-left:null,$font-size:null,$font-weight:null,$line-height:null){
  background-image: $bg-image;
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: $padding-left;
  font-size: $font-size;
  font-weight: $font-weight;
  color: $color;
  line-height: $line-height;
}

@mixin img-hover($opacity:0.8) {
    &:hover img{
    opacity: $opacity;
    filter: alpha(opacity=$opacity*10);
    -ms-filter: "alpha(opacity="+$opacity*10+")";
    -webkit-opacity: $opacity;
    -moz-opacity: $opacity;
  }
}



// PCのCSS調整
@media only screen and (max-width: 979px) {
  .container {
    height: auto !important;
  }


}


