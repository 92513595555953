@charset "utf-8";

@import "_mixin";
@import "_reset";

body {
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5;
  font-family: "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, Meiryo, sans-serif;
  color: #333;
  //text-align: center;
  @media only screen and (min-width: 980px) {
    font-size: 18px;
    font-family: "Noto Sans Japanese", "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, Meiryo, sans-serif;
  }
}
*, *:before, *:after{
  box-sizing: inherit;
}


input[type="submit"],button,label,select{
  cursor:pointer;
}
small{
  font-size: 70%;
  @media only screen and (max-width: 979px) {
    font-size: 90%;
  }
}

@media only screen and (max-width: 979px) {
  img{
    width: 100%;
  }
  .box{
    width: 100% !important;
  }
}

#page{
  overflow: hidden;
}

picture, img {
  //display: inline-block;
  vertical-align: bottom;
}
h1 {
}
h2{
  font-size: 30px;
  font-weight: bold;
  padding: 0 10px;
  margin-bottom: 22px;
  color: #101820;
  border-bottom: 5px solid #000;
  display: table;
  margin-right: auto;
  margin-left: auto;
  @media only screen and (max-width: 979px) {
    font-size: 17px;
    margin-bottom: 11px / $container-sp * 100%;
    border-bottom: 3px solid #000;
  }
}
h3{
}
h4{
}
p{
  margin-bottom: 20px;
  &:last-child{
    margin-bottom: 0;
  }
  @media only screen and (max-width: 979px) {
    margin-bottom: 20px / ($container-sp - 60px) * 100%;
  }

}
small, .small {
  font-size: 85%;
}

li{
  margin-left: 1.5em;
}
.indent {
    padding: 0;
    li {
        padding-left: 1em;
        text-indent: -1em;
        margin-left: 0;
        list-style-type: none;
    }
}
i.symbol {
    font-family: sans-serif;
    font-style: normal;
}

table{
//  border-top: 1px solid #95989A;
//  border-left: 1px solid #95989A;
  margin-bottom: 20px;
  tr{
    th,td{
//      border-right: 1px solid #95989A;
//      border-bottom: 1px solid #95989A;
      padding: 22px 0;
    }
    th{
      //font-weight: 500;
      text-align: center;
      width: 286px;
    }
    td{
    }
    &:nth-of-type(2n-1){
      background-color: #D9D9D9;
    }

  }
  @media only screen and (max-width: 979px) {
    margin-bottom: 20px / ($container-sp - 60px) * 100%;
    width: 100%;
    display: block;
    tbody,tr,th,td{
      width: 100% !important;
      display: block;
    }
    tr{
      background-color: transparent !important;
      th, td{
        padding-top: 6px / $container-sp * 100%;
        padding-bottom: 6px / $container-sp * 100%;
        text-align: center;
      }
      th{
        background-color: #D9D9D9;
      }
      td{
        background-color: #F2F2F2;
      }

    }



  }


}


.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.block-center{
  margin-right: auto !important;
  margin-left: auto !important;
}

.bc-yellow{
  background-color: #FFFF80;
}
.mb-0{
  margin-bottom: 0;
}
.mb-10{
  margin-bottom: 10px;
  @media only screen and (max-width: 979px) {
    margin-bottom: 10px / ($container-sp - 60px) * 100%;
  }
}
.mb-20{
  margin-bottom: 20px;
  @media only screen and (max-width: 979px) {
    margin-bottom: 20px / ($container-sp - 60px) * 100%;
  }
}

section {
  section {
    margin-bottom: 0;
    padding: 0;
  }
}

.container {
  width: auto;
  max-width: 960px;
  //padding-right: 10px;
  //padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}


.pc {
  display: initial;
  //margin-right: auto;
  //margin-left: auto;
}
.sp { display: none !important; }
@media only screen and (max-width: 979px) {
  .container {
    width: auto;
    padding: 0;
  }
    .pc { display: none !important; }
    .sp { display: initial !important; }
}



/* 共通 */


.flexbox-01{
  @include flexbox-01;
}
.flexbox-02{
  @include flexbox-02;
}
.flexbox-03{
  @include flexbox-03;
}


/* モジュール */

.over-img{
  div{
    width: 1500px;
    display: block;
    position: relative;
    left: 50%;
    margin-left: -750px;
    @media only screen and (max-width: 979px) {
      width: auto;
      left: 0;
      margin-left: 0;
    }
  }
}

.btn{
  a{
    display: block;
    color: #FFF;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
  }
  .mod-btn-01{
    border-radius: 8px;
    font-size: 16px;
    padding: 20px 10px;
    width: 300px;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.15);
    &.color-01{
      background-color: #F45757;
      box-shadow: 0 3px 0 0 #9D1B1B;
    }
    &.color-02{
      background-color: #103AC6;
      box-shadow: 0 3px 0 0 #00103C;
    }
  }
  .mod-btn-02{
    border-radius: 5px;
    font-size: 16px;
    padding: 9px;
    width: 120px;
    font-weight: normal;
    &.color-01{
      background-color: #55ACEF;
      box-shadow: 0 3px 0 0 #3C7AAA;
    }
    &.color-02{
      background-color: #3B5998;
      box-shadow: 0 3px 0 0 #263960;
    }
    &.color-03{
      background-color: #00C301;
      box-shadow: 0 3px 0 0 #018101;
    }
  }
  .mod-btn-03{
    border-radius: 8px;
    font-size: 16px;
    padding: 9px;
    width: 120px;
    font-weight: bold;
    background-color: #FFFFFF;
    border: 1px solid #76478F;
    color: #76478F;
    text-align: left;
    &:before{
      content: "\f105";
      font-family: FontAwesome;
      margin-right: 5px;
    }
  }
  @media only screen and (max-width: 979px) {
    .mod-btn-01,
    .mod-btn-02,
    .mod-btn-03{
      width: 100%;
    }
    .mod-btn-01{
      padding-top: 7px / 100px * 100%;
      padding-bottom: 7px / 100px * 100%;
      padding-right: 4px / 100px * 100%;
      padding-left: 4px / 100px * 100%;
      font-size: 15px;
    }
    .mod-btn-02{
      padding: 18px / 295px * 100%;
      font-size: 15px;
    }
    .mod-btn-03{
      font-size: 14px;
      padding: 13px / 275px * 100%;
    }
  }
}






/* 各区分 */
header.header{
  background-color: #000000;
  .inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 13px 19px 50px;
    .logo{
      img{
        width: 291px;
        height: auto;
      }
    }
    .global-nav{
      ul{
        display: flex;
        justify-content: space-between;
        list-style-type: none;
        a {
          color: #fff;
          text-decoration:  none;
          border-bottom: 1px solid #fff;
          padding: 0 9px 5px 9px;
          font-weight: 500;
        }
      }

    }
  }
  @media only screen and (max-width: 979px) {
    .inner{
      flex-wrap: wrap;
      padding-top: 25px / 2 / $container-sp * 100%;
      padding-right: 25px / 2 / $container-sp * 100%;
      padding-bottom: 25px / 2 / $container-sp * 100%;
      padding-left: 50px / 2 / $container-sp * 100%;
      .logo{
        width: 46%;
        img{
          width: 100%;
        }
      }
      .sp{
        display: flex !important;
        justify-content: space-between;
        .tel,
        .menu-toggle{
          border: 2px solid #fff;
          border-radius: 5px;
          line-height: 1;
          color: #fff;
          .fa-phone,
          .fa-bars{
          font-size: 31px;
          padding: 4px;
          width: 31px;

          }
          a{
            color: #fff;
          }
        }
        .menu-toggle{
          margin-left: 12px;
        }
      }
      .global-nav{
        display: none;
        width: 100%;
        ul{
          justify-content: flex-start;
          flex-direction: column;
          padding-top: 36px / 2 / $container-sp * 100%;
          li{
            margin: 0;
            a {
              color: #fff;
              padding: 20px / 2 / $container-sp * 100%;
              display: block;
            }
            &:nth-of-type(1){
//              border-top: 1px solid #fff;
            }
            &:last-child a{
              border: none;
              padding-bottom: 0;
            }
          }
        }

      }
    }

  }


}


#main{
  padding-top: 50px;
  padding-bottom: 80px;
  @media only screen and (max-width: 979px) {
    padding-top: 50px / 2 / $container-sp * 100%;
    padding-bottom: 60px / 2 / $container-sp * 100%;
  }


#sns{
  margin-bottom: 50px;
  .embed{
    width: 410px;
  }
  @media only screen and (max-width: 979px) {
    margin-bottom: 50px / 2 / $container-sp * 100%;
    .embed{
      width: 610px / 2 / $container-sp * 100%;
      margin-right: auto;
      margin-left: auto;
    }
    .facebook{
      margin-bottom: 50px / 2 / $container-sp * 100%;
    }
  }
}

#company{
  margin-bottom: 50px;
  .container{
    width: 750px;
    margin: 0 auto;
  }
  @media only screen and (max-width: 979px) {
    margin-bottom: 50px / 2 / $container-sp * 100%;
    .container{
      width: 100%;
    }
  }
}

#access{
  .container{
    width: 750px;
    margin: 0 auto;
    .map{
      #map_area{
        height: 420px;
      }
    }
  }
  @media only screen and (max-width: 979px) {
    .container{
      width: 650px / 2 / $container-sp * 100%;
      margin-right: auto;
      margin-left: auto;
      .map{
        #map_area{
          height: 250px;
        }
      }
    }
  }
}



#s1{
  padding-bottom: 130px;
  @media only screen and (max-width: 979px) {
    padding-right: 30px / $container-sp * 100%;
    padding-left: 30px / $container-sp * 100%;
  }
  .p1{
    .map-link{
      margin-left: 15px;
    }
    @media only screen and (max-width: 979px) {
      .map-link{
        margin-left: 0;
        margin-top: 10px / ($container-sp - 60px) * 100%;
        display: block;
      }
      table.color-02{
        margin-bottom: 30px / ($container-sp - 60px) * 100%;
      }
    }

  }
  .p2{
    @media only screen and (max-width: 979px) {
      h3{
        margin-top: 100px / ($container-sp - 60px) * 100%;
      }
    }
  }
  .p4{
    .img{
      margin-top: 20px;
    }
  }


}


}


footer.footer{
  background-color: #000;
  position: relative;
  color: #fff;
  .logo{
    text-align: center;
    padding: 25px 0 18px 0;
    img{
      width: 312px;
      height: auto;
    }
    span{
      display: block;
      font-size: 22px;
      padding-left: 66px;
      line-height: 1.1;
    }
    @media only screen and (max-width: 979px) {
      padding-top: 47px / 2 / $container-sp * 100%;
      padding-bottom: 42px / 2 / $container-sp * 100%;
      img{
        width: 400px / 2 / $container-sp * 100%;
        height: auto;
      }
      span{
        font-size: 15px;
        padding-left: 270px / 2 / $container-sp * 100%;
        text-align: left;
      }
    }
  }
  .contact{
    .container{
      max-width: 700px;
      padding: 18px 36px 26px 36px;
      border-top: 1px solid #fff;
      .mail-box{
        .text{
          font-size: 16px;
        }
      }
      .tel,
      .mail{
        font-size: 24px;
        font-weight: 500;
        display: flex;
        align-items: center;
        a{
          color: inherit;
          text-decoration: none;
        }
        .fa-phone,
        .fa-envelope{
          border: 2px solid #fff;
          border-radius: 5px;
          font-size: 30px;
          padding: 3px;
          margin-right: 7px;
        }
      }
      .text{
        padding-left: 37px;
      }
    }
    @media only screen and (max-width: 979px) {
      width: 580px / 2 / $container-sp * 100%;
      margin: 0 auto;
      text-align: center;
      .container{
        padding: 0;
        padding-top: 40px / 2 / $container-sp * 100%;
        padding-bottom: 40px / 2 / $container-sp * 100%;
        .tel,
        .mail{
          justify-content: center;
          font-size: 26px;
        }
        .text{
          padding-left: 0;
        }
        .tel-box{
          margin-bottom: 43px / 2 / $container-sp * 100%;
          .text{
            padding-left: 180px / 2 / $container-sp * 100%;
            text-align: left;
          }
        }
        .mail-box{
          .mail{
            font-size: 20px;
            margin-bottom: 6px / 2 / $container-sp * 100%;
          }
          .text{
            font-size: 12px;
          }
        }
      }

    }
  }
  #totop{
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10;
    font-size: 30px;
    a{
      color: #666;
      opacity: 0.6;
    }
    @media only screen and (max-width: 979px) {
      right: 10px;
//      font-size: 18px;
    }
  }
  #copyright{
    font-weight: normal;
    padding-bottom: 23px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    @media only screen and (max-width: 979px) {
      font-size: 9px;
      padding-top: 10px / 2 / $container-sp * 100%;
      padding-bottom: 40px / 2 / $container-sp * 100%;
    }

  }
}


